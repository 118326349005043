import React from 'react';
import Logotype from './SVG/Logotype';

const Navbar = () => {
  return (
    <div data-scroll='top' className='section no-overflow nav-section'>
      <div className='container'>
        <div className='nav-wrapper'>
          <a href='/' className='nav-logo-link'>
            <Logotype />
          </a>

          <nav className='nav-main-menu-wrapper'>
            <ul className='language-selector'>
              <li className='language-select is-active'>
                <a href='#'>PL</a>
              </li>
              <li className='language-select'>
                <a href='#'>EN</a>
              </li>
            </ul>
            <ul className='nav-main-menu'>
              <li className='nav-menu-item is-active'>
                <a href='#services' className='scrollTo'>
                  Usługi
                </a>
              </li>
              <li className='nav-menu-item'>
                <a href='#team' className='scrollTo'>
                  Team
                </a>
              </li>
              <li className='nav-menu-item'>
                <a href='#' className=''>
                  Projekty
                </a>
              </li>
              <li className='nav-menu-item'>
                <a href='#' className=''>
                  Artykuły
                </a>
              </li>
              <li className='nav-menu-item'>
                <a href='#' className=''>
                  Partnerzy
                </a>
              </li>
              <li className='nav-menu-item'>
                <a href='#' className=''>
                  Kontakt
                </a>
              </li>
            </ul>
          </nav>
          <div className='burger-menu'>
            <span className='line' />
            <span className='line' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
