import React from 'react';
import Behance from './SVG/Behance';
import Dribble from './SVG/Dribble';
import InVision from './SVG/InVision';

const Contractors = () => {
  return (
    <div className='section contractors'>
      <div className='container is-aligned-left contractors-container'>
        <div className='contractors-header'>
          <h2 data-aos='fade-up' className='contractors-heading'>
            Partnerzy
          </h2>
          <h3 data-aos='fade-up' className='contractors-subheading'>
            Mieliśmy przyjemność <br />
            współpracować
          </h3>
        </div>
        <div className='contractors-slider'>
          <div data-aos='fade-up' className='contractors-line-wrapper'>
            <span className='contractors-line' />
          </div>
          <ul
            data-aos='fade-up'
            data-aos-delay='200'
            className='contractors-list'
          >
            <li className='contractors-list-element is-active'>
              <Behance />
            </li>
            <li className='contractors-list-element'>
              <Dribble />
            </li>
            <li className='contractors-list-element'>
              <InVision />
            </li>
            <li className='contractors-list-element'>
              <Dribble />
            </li>
            <li className='contractors-list-element'>
              <InVision />
            </li>
            <li className='contractors-list-element'>
              <Behance />
            </li>
          </ul>
        </div>
        <div data-aos='fade-up' className='contractor-wrapper'>
          <div className='contractor-details is-active'>
            <h2 className='contractor-name'>Behance</h2>
            <p className='contractor-description'>
              Mauris sed egestas velit. Interdum et malesuada fames ac ante
              ipsum primis in faucibus. Fusce vitae turpis sem. Nam ac eros
              ipsum. Praesent sollicitudin hendrerit dictum.
            </p>
          </div>
          <div className='contractor-details'>
            <h2 className='contractor-name'>Dribble</h2>
            <p className='contractor-description'>
              Mauris sed egestas velit. Interdum et malesuada fames ac ante
              ipsum primis in faucibus. Fusce vitae turpis sem. Nam ac eros
              ipsum. Praesent sollicitudin hendrerit dictum.
            </p>
          </div>
          <div className='contractor-details'>
            <h2 className='contractor-name'>inVision</h2>
            <p className='contractor-description'>
              Mauris sed egestas velit. Interdum et malesuada fames ac ante
              ipsum primis in faucibus. Fusce vitae turpis sem. Nam ac eros
              ipsum. Praesent sollicitudin hendrerit dictum.
            </p>
          </div>
          <div className='contractor-details'>
            <h2 className='contractor-name'>Dribble2</h2>
            <p className='contractor-description'>
              Mauris sed egestas velit. Interdum et malesuada fames ac ante
              ipsum primis in faucibus. Fusce vitae turpis sem. Nam ac eros
              ipsum. Praesent sollicitudin hendrerit dictum.
            </p>
          </div>
          <div className='contractor-details'>
            <h2 className='contractor-name'>inVision2</h2>
            <p className='contractor-description'>
              Mauris sed egestas velit. Interdum et malesuada fames ac ante
              ipsum primis in faucibus. Fusce vitae turpis sem. Nam ac eros
              ipsum. Praesent sollicitudin hendrerit dictum.
            </p>
          </div>
          <div className='contractor-details'>
            <h2 className='contractor-name'>Behance2</h2>
            <p className='contractor-description'>
              Mauris sed egestas velit. Interdum et malesuada fames ac ante
              ipsum primis in faucibus. Fusce vitae turpis sem. Nam ac eros
              ipsum. Praesent sollicitudin hendrerit dictum.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contractors;
