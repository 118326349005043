import React from 'react';

const LinkedIn = props => (
  <svg width={42} height={44} {...props}>
    <path d='M20.982 41.965C9.964 41.965 1 33.001 1 21.983 1 10.964 9.964 2 20.982 2c11.019 0 19.983 8.964 19.983 19.983 0 11.018-8.964 19.982-19.983 19.982zm0-38.446c-10.18 0-18.463 8.283-18.463 18.464 0 10.18 8.282 18.463 18.463 18.463 10.181 0 18.464-8.282 18.464-18.463 0-10.181-8.282-18.464-18.464-18.464z' />
    <path d='M12.451 19.077h3.64v11.535h-3.64V19.077zm13.588-.111c2.587 0 4.62 1.66 4.62 5.232v6.414H26.53v-5.969c0-1.563-.646-2.63-2.066-2.63-1.086 0-1.69.72-1.971 1.414-.106.25-.09.596-.09.943v6.242h-4.09s.054-10.574 0-11.535h4.09v1.81c.242-.791 1.549-1.92 3.635-1.92zm-11.835-5.334c1.343 0 2.169.861 2.195 2 0 1.117-.852 2.002-2.22 2.002h-.027c-1.317 0-2.172-.881-2.172-2 0-1.139.88-2.002 2.224-2.002z' />
  </svg>
);

export default LinkedIn;
