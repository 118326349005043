import React from 'react';
import Slide from './Slide';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class HomeSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
    };
  }

  goToSlide = index => {
    const { data } = this.props;

    this.setState({
      currentSlide: (index + data.length) % data.length,
    });

    // animateSlider(slideshowElements, mainSliderAnimatedElements, mainSliderRemoveClasses, currentSlide, toggleSliderAnimation());
  };

  pauseSlideshow = () => {
    clearInterval(this.slideInterval);
  };

  playSlideshow = () => {
    this.slideInterval = setInterval(() => {
      this.goToSlide(this.state.currentSlide + 1);
    }, 5000);
  };

  componentWillUnmount() {
    this.pauseSlideshow();
  }

  componentDidMount() {
    this.playSlideshow();
  }

  render() {
    const { data } = this.props;
    const { currentSlide } = this.state;

    return (
      <div className='section slider is-dark'>
        <div
          className='container hero-slider-container'
          onMouseEnter={this.pauseSlideshow}
          onMouseLeave={this.playSlideshow}
        >
          <ul className='hero-slider'>
            {data.map((data, index) => (
              <Slide
                key={index}
                isActive={index === currentSlide}
                data={data}
              />
            ))}
          </ul>
          <ul className='thumbnail-slider'>
            {data.map((data, index) => (
              <li
                key={index}
                className={classNames({
                  'thumbnail-slide': true,
                  'is-active': index === currentSlide,
                })}
                onClick={() => this.goToSlide(index)}
              >
                <button className='thumbnail-slide-link'>
                  <img
                    src={data.sm}
                    alt={data.alt}
                    className='thumbnail-slide-image'
                  />
                  <h3 className='thumbnail-slide-heading'>{data.subHeader}</h3>
                </button>
              </li>
            ))}
          </ul>
          <hr className='horizontal-rule' />
        </div>
        <div className='container is-aligned-left'>
          <div className='button-wrapper is-aligned-right'>
            <a href='#' className='underline-btn is-bright'>
              Pokaż wszystkie
            </a>
          </div>
        </div>
      </div>
    );
  }
}

HomeSlider.propTypes = {
  data: PropTypes.array,
};

export default HomeSlider;
