import licensingSm from '../../assets/img/licensing-sm.jpg';
import licensingMd from '../../assets/img/licensing-md.jpg';
import licensingLg from '../../assets/img/licensing-lg.jpg';
import areasSm from '../../assets/img/business-areas-sm.jpg';
import areasMd from '../../assets/img/business-areas-md.jpg';
import areasLg from '../../assets/img/business-areas-lg.jpg';
import regtechSm from '../../assets/img/regtech-sm.jpg';
import regtechMd from '../../assets/img/regtech-md.jpg';
import regtechLg from '../../assets/img/regtech-lg.jpg';

export const data = [
  {
    sm: licensingSm,
    md: licensingMd,
    lg: licensingLg,
    alt: 'Some text for SEO',
    header: 'Some Heading text',
    subHeader: 'PLAVE',
    linkTo: 'http://google.com',
  },
  {
    sm: areasSm,
    md: areasMd,
    lg: areasLg,
    alt: 'Some text for SEO',
    header: 'Some Heading text',
    subHeader: 'PLAVE2',
    linkTo: 'http://google.com',
  },
  {
    sm: regtechSm,
    md: regtechMd,
    lg: regtechLg,
    alt: 'Some text for SEO',
    header: 'Some Heading text',
    subHeader: 'PLAVE3',
    linkTo: 'http://google.com',
  },
  {
    sm: licensingSm,
    md: licensingMd,
    lg: licensingLg,
    alt: 'Some text for SEO',
    header: 'Some Heading text',
    subHeader: 'PLAVE4',
    linkTo: 'http://google.com',
  },
  {
    sm: licensingSm,
    md: licensingMd,
    lg: licensingLg,
    alt: 'Some text for SEO',
    header: 'Some Heading text',
    subHeader: 'PLAVE5',
    linkTo: 'http://google.com',
  },
];
