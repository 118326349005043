import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import BtnArrow from '../SVG/BtnArrow';

const Slide = props => {
  const { data, isActive } = props;

  const slideClasses = classNames({
    'hero-slide': true,
    'is-active': isActive,
  });

  const animationClasses = classNames({
    'js-animate is-animated': true,
    'is-hidden': !isActive,
  });

  const transitionClasses = classNames({
    'fades-to-right': !isActive,
  });

  // const slides = document.querySelectorAll('.hero-slide');
  // const slidesContainer = document.querySelector('.hero-slider-container');
  // const thumbnails = document.querySelectorAll('.thumbnail-slide');
  // const slideshowElements = [ slides, thumbnails ];
  // const mainSliderRemoveClasses = ['is-hidden', 'fades-to-right'];
  // const mainSliderAnimatedElements = [
  //   ['.slide-heading', 200],
  //   ['.slide-background', 100],
  //   ['.slide-subheading', 250],
  //   ['.btn', 200],
  // ];

  // this.toggleSliderAnimation();

  // toggleSliderAnimation = () => {
  //   slides.forEach(el => {
  //     if(!el.classList.contains('is-active')) {
  //       const header = el.querySelector('.slide-heading');
  //       const subheader = el.querySelector('.slide-subheading');
  //       const button = el.querySelector('.btn');
  //       const image = el.querySelector('.slide-background');
  //       const elementsArray = [ header, subheader, button ];

  //       image.classList.add('js-animate', 'is-animated', 'is-hidden');
  //       elementsArray.forEach(item => {
  //         item.classList.add('js-animate', 'is-animated', 'is-hidden', 'fades-to-right');
  //       });
  //     }
  //   });
  // }

  // removeClass = (element, className) => {
  //   if(element.classList.contains(className)) element.classList.remove(className);
  // }

  // removeSliderClasses = (element, array, time) => {
  //   setTimeout(() => {
  //     array.forEach(arrEl => {
  //       removeClass(element, arrEl);
  //     });
  //   }, time);
  // }

  // animateSlider = (elements, animatedElementsArr, removeClassesArr ,current, func) => {
  //   elements.forEach( (element, index) => {
  //     element[current].classList.add('is-active');
  //     if(index === 0) {
  //       animatedElementsArr.forEach(elArr => {
  //         removeSliderClasses(element[current].querySelector(elArr[0]), removeClassesArr, elArr[1]);
  //       });
  //     }
  //   });
  //   setTimeout(() => { func }, 500);
  // }

  return (
    <li className={slideClasses}>
      <picture>
        <source srcSet={data.lg} media='(min-width: 1024px)' />
        <source srcSet={data.md} media='(min-width: 768px)' />
        <img
          srcSet={data.sm}
          alt={data.alt}
          className={`slide-background ${animationClasses}`}
        />
      </picture>
      <div className='container slide-content-container'>
        <div className='slide-content'>
          <Transition
            in={true}
            timeout={{
              enter: 300,
              exit: 500,
            }}
            unmountOnExit
          >
            <h2
              className={`slide-heading ${animationClasses} ${transitionClasses}`}
            >
              {data.header}
            </h2>
          </Transition>

          <h3
            className={`slide-subheading ${animationClasses} ${transitionClasses}`}
          >
            {data.subHeader}
          </h3>
          <a
            href={data.linkTo}
            className={`btn small colored bright ${animationClasses} ${transitionClasses}`}
          >
            <span className='btn-text'>więcej</span>
            <div className='btn-arrow'>
              <BtnArrow className='arrow-img' role='presentation' />
            </div>
          </a>
        </div>
      </div>
    </li>
  );
};

Slide.propTypes = {
  isActive: PropTypes.bool,
  data: PropTypes.object.isRequired,
};

export default Slide;
