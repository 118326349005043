import React from 'react';

const RegTechFooter = () => {
  return (
    <div className='regtech-footer'>
      <div className='reduced-content'>
        <h3 data-aos='fade-up' className='regtech-footer-header h-28'>
          Nagłówek
        </h3>
        <div className='regtech-columns-wrapper'>
          <p data-aos='fade-up' className='regtech-column'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem
            augue, pulvinar vel nisi condimentum, volutpat ultrices nisl. Duis
            ultrices nisl id lacus consectetur viverra. Ut laoreet libero sem.
            Praesent porttitor vulputate nibh nec egestas. Duis consectetur
            lorem nulla, ac accumsan dolor feugiat porta. Fusce
          </p>
          <p data-aos='fade-up' data-aos-delay='100' className='regtech-column'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem
            augue, pulvinar vel nisi condimentum, volutpat ultrices nisl. Duis
            ultrices nisl id lacus consectetur viverra. Ut laoreet libero sem.
            Praesent porttitor vulputate nibh nec egestas. Duis consectetur
            lorem nulla, ac accumsan dolor feugiat porta. Fusce
          </p>
          <p data-aos='fade-up' data-aos-delay='150' className='regtech-column'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem
            augue, pulvinar vel nisi condimentum, volutpat ultrices nisl. Duis
            ultrices nisl id lacus consectetur viverra. Ut laoreet libero sem.
            Praesent porttitor vulputate nibh nec egestas. Duis consectetur
            lorem nulla, ac accumsan dolor feugiat porta. Fusce
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegTechFooter;
