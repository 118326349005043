import React from 'react';

const ScrollSpy = () => {
  return (
    <div className='scroll-spy'>
      <ul className='spy-list'>
        <li className='spy-list-element is-active'>
          <a href='#top' className='spy-list-link is-home scrollTo'>
            <span className='spyscroll-dot' />
          </a>
        </li>
        <li className='spy-list-element'>
          <a
            href='#services'
            data-scrollto='services'
            className='spy-list-link scrollTo'
          >
            <span className='spy-list-text'>Usługi</span>
            <span className='spyscroll-number'>01</span>
          </a>
        </li>
        <li className='spy-list-element'>
          <a
            href='#areas'
            data-scrollto='areas'
            className='spy-list-link scrollTo'
          >
            <span className='spy-list-text'>Obszary działalności</span>
            <span className='spyscroll-number'>02</span>
          </a>
        </li>
        <li className='spy-list-element'>
          <a
            href='#team'
            data-scrollto='team'
            className='spy-list-link scrollTo'
          >
            <span className='spy-list-text'>Team</span>
            <span className='spyscroll-number'>03</span>
          </a>
        </li>
        <li className='spy-list-element'>
          <a href='#' data-scrollto='projects' className='spy-list-link'>
            <span className='spy-list-text'>Projekty</span>
            <span className='spyscroll-number'>04</span>
          </a>
        </li>
        <li className='spy-list-element'>
          <a href='#' className='spy-list-link'>
            <span className='spy-list-text'>Co u nas</span>
            <span className='spyscroll-number'>05</span>
          </a>
        </li>
        <li className='spy-list-element'>
          <a href='#' className='spy-list-link'>
            <span className='spy-list-text'>Partnerzy</span>
            <span className='spyscroll-number'>06</span>
          </a>
        </li>
        <li className='spy-list-element'>
          <a href='#' className='spy-list-link'>
            <span className='spy-list-text'>Kontakt</span>
            <span className='spyscroll-number'>07</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ScrollSpy;
