import React from 'react';

const Chevron = props => (
  <svg width={201.462} height={201.463} {...props}>
    <path
      fill='#030303'
      d='M8.28 155.229L0 146.95 100.723 46.222l100.739 100.74-8.279 8.279-92.46-92.455L8.28 155.229z'
    />
  </svg>
);

export default Chevron;
