import React from 'react';

const HeroSection = props => {
  return (
    <div data-scroll='areas' className={`section hero ${props.sectionName}`}>
      <div className='container'>
        <div className='hero-content'>
          <h2 data-aos='fade-up' className='hero-header'>
            {props.title}
          </h2>
          <p data-aos='fade-up' className='hero-paragraph'>
            {props.children}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
