import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import Bio from '../components/Bio';
import Layout from '../components/Layout';
import ScrollSpy from '../components/ScrollSpy';
import Navbar from '../components/Navbar';
import HeaderHome from '../components/HeaderHome';
import MapSection from '../components/MapSection';
import PartnerSection from '../components/PartnerSection';
import HomeSlider from '../components/HomeSlider/';
import HomeBlog from '../components/HomeBlog';
import Contractors from '../components/Contractors';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import FeatureBox from '../components/common/FeatureBox';
import FeatureBoxFooter from '../components/common/FeatureBoxFooter';
import RegTechFooter from '../components/common/RegTechFooter';
import style from '../assets/scss/style.scss';
import { data } from '../components/staticData/homepageSlides';
import { partnersData } from '../components/staticData/homepagePartners';

class BlogIndex extends React.Component {
  render() {
    const config = get(this, 'props.data.config');
    const posts = get(this, 'props.data.allMarkdownRemark.edges');
    const siteTitle = get(config, 'frontmatter.title');
    const description = get(config, 'frontmatter.description');
    const bio = get(config, 'html');

    return (
      <Layout location={this.props.location} config={config} initAOS>
        <Helmet
          htmlAttributes={{ lang: this.props.pageContext.language }}
          meta={[{ name: 'description', content: description }]}
          title={siteTitle}
        />
        <Bio>
          <div dangerouslySetInnerHTML={{ __html: bio }} />
        </Bio>

        <div>
          <div data-scroll='top' className='site-container'>
            <ScrollSpy location={this.props.location} />

            <Navbar location={this.props.location} />

            <HeaderHome location={this.props.location} />

            <HeroSection sectionName='is-licence' title='Pozyskiwanie licencji'>
              <strong>Nie boimy się wyzwań!</strong> Dzięki naszemu
              doświadczeniu, relacjami z lokalnymi organami nadzoru i rozległym
              kontaktom
              <strong>
                jesteśmy w stanie z sukcesem zrealizować dowolny proces
                licencyjny
              </strong>
              w wybranej jurysdykcji na świecie.
            </HeroSection>
            <div className='section no-overflow regular-padding'>
              <div className='container'>
                <ul className='feature-boxes-wrapper reduced-content'>
                  <FeatureBox title='EMI' size='full'>
                    <strong>
                      Odpowiadamy za najszybszy proces licencyjny Instytucji
                      Pieniądza Elektronicznego (EMI), zajęło nam to mniej niż 3
                      miesiące.
                    </strong>
                    Uczestniczymy w całym procesie licencyjnym, łącznie z{' '}
                    <strong>kompleksową obsługą procesu autoryzacji</strong>, aż
                    do wydania decyzji przez odpowiedni organ nadzoru
                    finansowego.
                    <ul>
                      <li>
                        <strong>Realizujemy</strong> procesy licencyjne i
                        reautoryzacji spójne z wymogami jakie nakreśla PSD2.
                      </li>
                      <li>
                        <strong>Wspieramy</strong> przedsiębiorstwa
                        licencjonowane w paszportyzacji na inne kraje.
                      </li>
                      <li>
                        <strong>Pomagamy</strong> w budowie modelu biznesowego i
                        technologicznego.
                      </li>
                      <li>
                        <strong>Koordynujemy</strong> wdrażanie zmieniających
                        się wymgów regulacyjnych.
                      </li>
                    </ul>
                  </FeatureBox>
                  <FeatureBox title='Bank' size='half'>
                    <strong>Pozyskujemy licencje bankowe</strong>, w
                    szczególności we współpracy z nadzorem na Litwie, w Polsce
                    oraz na Malcie.
                    <ul>
                      <li>
                        Pomagamy w <strong>wypełnieniu dokumentacji</strong>{' '}
                        oraz <strong>spełnieniu wymogów formalnych</strong>{' '}
                        narzuconych przez EBC i lokalny organ nadzoru.
                      </li>
                      <li>
                        <strong>Wspieramy procesy paszportyzacji</strong>{' '}
                        licencji na kolejne kraje.
                      </li>
                      <li>
                        Nasza rola nie ogranicza się jedynie do organizacji
                        procesu licencyjnego,{' '}
                        <strong>stanowimy jego element.</strong>
                      </li>
                    </ul>
                  </FeatureBox>
                  <FeatureBox title='Payment Institution' size='half'>
                    <strong>Określamy odpowiedni rodzaj licencji</strong>{' '}
                    (payment institution, payment institution license for
                    restricted activity).
                    <ul>
                      <li>
                        Prowadzimy proces licencyjny{' '}
                        <strong>optymalizując</strong> kluczowe elementy, np.{' '}
                        <strong>
                          wymogi kapitałowe i metody ich szacowania
                        </strong>
                        .
                      </li>
                      <li>
                        Pracujemy przy obsłudze finansowej i{' '}
                        <strong>projektowaniu polityki raportowania</strong> do
                        odpowiednich organów nadzoru.
                      </li>
                      <li>
                        Określamy wymogi lokalnego nadzoru w zakresie struktury
                        organizacyjnej przedsiębiorstwa.
                      </li>
                    </ul>
                  </FeatureBox>
                  <FeatureBox title='TPP' size='one-third'>
                    <strong>
                      Zajmujemy się pozyskiwaniem licencji AISP i PISP.
                    </strong>{' '}
                    Wspólnie z wnioskodawcą wypracowujemy kompletną dokumentację
                    wymaganą przez nadzór. Określamy m.in. polityki zarządzania,
                    mechanizmy kontroli wewnętrznej, politykę zarządzania
                    ryzykiem, politykę rachunkowości.
                  </FeatureBox>
                  <FeatureBox title='Ubezpieczenia' size='one-third'>
                    <strong>
                      Działamy również w obszarze licencjonowania działalności
                      ubezpieczeniowej.
                    </strong>
                    Wspieramy Klientów w określeniu wymogów formalnych i
                    dokumentowych w celu ubiegania się o licencję oraz
                    uczestniczymy na rzecz klienta w procesie autoryzacji.
                  </FeatureBox>
                  <FeatureBox title='Blockchain' size='one-third' isLast={true}>
                    Wspieramy przedsiębiorstwa w pozyskiwaniu licencji
                    pozwalającej na{' '}
                    <strong>świadczenie usług wymiany kryptowalut</strong> na
                    Malcie. Jesteśmy na bieżąco z europejskimi zmianami
                    legislacyjnymi w zakresie technologii blockchain.
                  </FeatureBox>
                </ul>
                <FeatureBoxFooter />
              </div>
            </div>

            <HeroSection sectionName='is-regtech' title='RegTech'>
              Z powodzeniem wspieraliśmy naszych klientów we wdrażaniu
              najnowszych zmian legislacyjnych, które najmocniej zmieniają rynek
              usług finansowych. Członkowie naszego zespołu uczestniczyli w
              implementacji Dyrektywy BRRD, która dotyczy przymusowej
              restrukturyzacji banków. Dokonywaliśmy wycen aktywów bankowych i
              wdrażaliśmy instrumenty restrukturyzacyjne na potrzeby przejęć,
              czy likwidacji.
            </HeroSection>

            <div className='section regular-padding'>
              <div className='container'>
                <ul className='feature-boxes-wrapper align-line-to-right reduced-content'>
                  <FeatureBox title='AML' size='full'>
                    <strong>
                      Implementujemy w instytucjach finansowych najnowsze
                      regulacje w zakresie przeciwdziałania praniu pieniędzy
                      (AML 4)
                    </strong>
                    zarówno w sferze procesowej jak i IT poprzez wdrażanie
                    technologii.
                    <ul>
                      <li>
                        Prezentujemy kompleksowe podejście do analizy ryzyka,
                        identyfikacji beneficjenta rzeczywistego, rozszerzenia
                        definicji osób zajmujących eksponowane stanowiska
                        polityczne oraz limitów rejestracji transakcji.
                      </li>
                      <li>
                        Wśród naszych klientów, na rzecz których pozyskiwaliśmy
                        licencje finansowe wdrażaliśmy również zaawansowane
                        systemy chroniące prywatność użytkowników.
                      </li>
                    </ul>
                  </FeatureBox>
                  <FeatureBox title='PSD2' size='half'>
                    <strong>
                      W ramach PSD2 wspieramy Klientów w pozyskaniu licencji TPP
                      oraz rozwoju systemów transakcyjnych i IT.
                    </strong>
                    <ul>
                      <li>
                        Prowadzimy procesy reautoryzacji, które zostały
                        wymuszone przez daleko idące zmiany określone w PSD2.
                      </li>
                      <li>
                        <strong>Koordynujemy procesy</strong> wdrażania nowych
                        rodzajów usług płatniczych i
                        <strong>innowacji technologicznych</strong> w obszarze
                        płatności realizowanych za pośrednictwem urządzeń
                        przenośnych.
                      </li>
                    </ul>
                  </FeatureBox>
                  <FeatureBox title='IFRS9' size='half'>
                    <strong>Wdrażamy IFRS9 w instytucjach finansowych.</strong>
                    <ul>
                      <li>
                        Przeprowadzamy{' '}
                        <strong>
                          przegląd zasad klasyfikacji i wyceny aktywów
                          finansowych, modeli wyznaczania oczekiwanych strat
                          kredytowych
                        </strong>{' '}
                        oraz wypełnienia wymogów w zakresie rachunkowości
                        zabezpieczeń.
                      </li>
                      <li>
                        Opracowujemy zasady szacowania aktywów finansowych i
                        portfeli pożyczkowych oraz elementy rachunkowości
                        instrumentów finansowych.
                      </li>
                    </ul>
                  </FeatureBox>
                </ul>
                <RegTechFooter />
              </div>
            </div>

            <HeroSection sectionName='is-areas' title='Obszary działalności'>
              Nasza działalność ma zasięg międzynarodowy. Nasze rodzime
              otoczenie stanowią rynki europejskie, ze względu na doświadczenie
              wyniesione z sektora finansowego i europejskich organów nadzoru.
              Swoje usługi oferujemy także klientom z Azji poprzez sieć naszych
              ekspertów i partnerów branżowych.
            </HeroSection>

            <MapSection />

            {partnersData.map((data, index) => (
              <PartnerSection
                key={index}
                sm={data.sm}
                md={data.md}
                lg={data.lg}
                name={data.name}
                surname={data.surname}
                alt={data.alt}
                link={data.linkTo}
                aligned={data.aligned}
              >
                {data.content}
              </PartnerSection>
            ))}

            <HomeSlider data={data} />

            <HomeBlog />

            <Contractors />
          </div>

          <Footer />
        </div>

        <div style={{ display: 'none' }}>
          {posts.map(({ node }) => {
            const title = get(node, 'frontmatter.title') || node.fields.slug;
            return (
              <div key={node.fields.slug}>
                <h3>
                  <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h3>
                <small>{node.frontmatter.date}</small>
                <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
              </div>
            );
          })}
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const blogIndexFragment = graphql`
  query BlogPost($language: String!) {
    config: markdownRemark(
      frontmatter: { language: { eq: $language }, type: { eq: "language" } }
    ) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        language
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { language: { eq: $language }, type: { eq: null } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "LL")
            title
          }
        }
      }
    }
  }
`;
