import React from 'react';
import BtnArrow from '../SVG/BtnArrow';

const FeatureBoxFooter = () => {
  return (
    <div className='feature-box-footer'>
      <p data-aos='fade-up' className='feature-box-heading'>
        Najszybciej
      </p>
      <div className='feature-box-cta'>
        <p data-aos='fade-up'>
          przeprowadzony proces
          <br />
          licencyjny na świecie
          <span className='feature-box-line' />
        </p>
        <a href='#' className='btn medium colored'>
          <span className='btn-text'>więcej</span>
          <div className='btn-arrow'>
            <BtnArrow className='arrow-img' role='presentation' />
          </div>
        </a>
      </div>
    </div>
  );
};

export default FeatureBoxFooter;
