import React from 'react';
import ScrollDown from './SVG/ScrollDown';
import postImage from '../assets/img/licensing-sm.jpg';

const HomeBlog = () => {
  return (
    <div className='section no-overflow blog'>
      <div className='container is-aligned-left blog-heading-container'>
        <div className='blog-heading-wrapper'>
          <div className='blog-line-wrapper'>
            <span className='blog-line-top' />
            <span
              className='blog-line-bottom'
              data-aos='height-animation'
              data-aos-delay='50'
              data-aos-duration='1500'
            />
          </div>
          <h2
            data-aos='fade-down'
            data-aos-duration='1000'
            className='blog-heading'
          >
            Co u nas
          </h2>
          <a
            data-aos='fade-down'
            data-aos-duration='1000'
            href='#'
            className='scroll-down is-dark'
          >
            <ScrollDown />
          </a>
        </div>
      </div>
      <div className='container is-aligned-left'>
        <ul className='blog-posts'>
          <li className='blog-post'>
            <a href='#' className='post-image-link'>
              <img src={postImage} alt='POST IMAGE' className='post-image' />
            </a>
            <a href='#' className='post-title-link'>
              <h2 className='post-title'>
                Jak w 14 dni poprawić swoje finanse osobiste?
              </h2>
            </a>
            <time className='post-date' dateTime='2018-06-14'>
              14 Jun. 2018
            </time>
          </li>
          <li className='blog-post'>
            <a href='#' className='post-image-link'>
              <img src={postImage} alt='POST IMAGE' className='post-image' />
            </a>
            <a href='#' className='post-title-link'>
              <h2 className='post-title'>
                Jak w 14 dni poprawić swoje finanse osobiste?
              </h2>
            </a>
            <time className='post-date' dateTime='2018-06-14'>
              14 Jun. 2018
            </time>
          </li>
          <li className='blog-post'>
            <a href='#' className='post-image-link'>
              <img src={postImage} alt='POST IMAGE' className='post-image' />
            </a>
            <a href='#' className='post-title-link'>
              <h2 className='post-title'>
                Jak w 14 dni poprawić swoje finanse osobiste?
              </h2>
            </a>
            <time className='post-date' dateTime='2018-06-14'>
              14 Jun. 2018
            </time>
          </li>
        </ul>
      </div>
      <div className='container'>
        <hr className='horizontal-rule is-dark' />
      </div>
      <div className='container is-aligned-left'>
        <div className='button-wrapper is-aligned-right'>
          <a href='#' className='underline-btn is-dark'>
            Pokaż wszystkie
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeBlog;
