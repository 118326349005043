import React from 'react';
import BtnArrow from '../SVG/BtnArrow';

const FeatureBox = props => {
  return (
    <li className={`feature-box ${props.size} ${props.isLast ? 'cf' : ''}`}>
      <h3 className='feature-box-title'>{props.title}</h3>
      <div className='feature-box-content'>{props.children}</div>
      <a href='#' className='btn small colored'>
        <span className='btn-text'>więcej</span>
        <div className='btn-arrow'>
          <BtnArrow className='arrow-img' role='presentation' />
        </div>
      </a>
    </li>
  );
};

export default FeatureBox;
