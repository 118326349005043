import React from 'react';

const BtnArrow = props => (
  <svg
    width='36'
    height='7'
    viewBox='0 0 36 7'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M30 3V0l6 3.5L30 7V4H0V3h30z' fill='#fff' fillRule='evenodd' />
  </svg>
);

export default BtnArrow;
