import React from 'react';
import ScrollDown from './SVG/ScrollDown';

const HeaderHome = () => {
  return (
    <div className='section header-home-section'>
      <header className='site-header'>
        <h1 className='site-header-claim'>
          We Deliver
          <a href='#services' className='scrollTo scroll-down'>
            <ScrollDown />
          </a>
        </h1>
      </header>
    </div>
  );
};

export default HeaderHome;
