import pawelSm from '../../assets/img/pawel-sm.jpg';
import pawelMd from '../../assets/img/pawel-md.jpg';
import pawelLg from '../../assets/img/pawel-lg.jpg';
import damianSm from '../../assets/img/damian-sm.jpg';
import damianMd from '../../assets/img/damian-md.jpg';
import damianLg from '../../assets/img/damian-lg.jpg';

export const partnersData = [
  {
    sm: pawelSm,
    md: pawelMd,
    lg: pawelLg,
    name: 'Paweł',
    surname: 'Łaskarzewski',
    alt: 'Paweł Łaskarzewski',
    linkTo: 'https://www.linkedin.com/in/pawellaskarzewski/',
    aligned: 'right',
    content: `Doświadczony menedżer o wysokich umiejętnościach analitycznych, technicznych i organizacyjnych.
              Zdobył doświadczenie zawodowe w takich firmach jak B/E Aerospace, WizzAir, Nestle, Coca-Cola,
              EuroSport i wiele innych. Jako współwłaściciel i CTO był współodpowiedzialny za sukces największej
              polskiej firmy HR dla studentów i absolwentów - Absolvent.`,
  },
  {
    sm: damianSm,
    md: damianMd,
    lg: damianLg,
    name: 'Damian',
    surname: 'Chojnowski',
    alt: 'Damian Chojnowski',
    linkTo: 'https://www.linkedin.com/in/damian-chojnowski-06118015b/',
    aligned: 'left',
    content: `Posiada szerokie doświadczenie w zakresie pozyskiwania finansowania korporacyjnego oraz ekspertyz w
              zakresie ryzyka kredytowego i analizy finansowej. Uczestniczył w tworzeniu strategii rozwoju i
              restrukturyzacji przedsiębiorstw oraz pozyskiwaniu finansowania korporacyjnego. W obszarze klienta
              detalicznego projektował i wdrażał modele scoringowe oceny wiarygodności i zdolności kredytowej
              klienta detalicznego.`,
  },
];
