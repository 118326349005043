import React from 'react';

const Logotype = props => (
  <svg
    className='nav-logo-image'
    width={337}
    height={42}
    viewBox='0 0 337 42'
    {...props}
  >
    <path
      className='plave-logo-claim'
      d='M30.6 14.4c0 8-6.4 14.4-14.7 14.4H9.6V42H0V0h15.9c8.3 0 14.7 6.4 14.7 14.4zm-9.6 0c0-3.1-2.2-5.4-5.1-5.4H9.6v10.8h6.3c2.9 0 5.1-2.3 5.1-5.4zm68.6 18.4V42H65V0h9.6v32.8h15zm59 2.6H133l-2.1 6.6h-10.5l14.3-42h12.2l14.3 42h-10.5l-2.1-6.6zm-2.9-9L140.8 11l-4.9 15.4h9.8zM191.4 0h10.5l9.3 30.9L220.5 0H231l-13.7 42h-12.2L191.4 0zm100 32.8V42H265V0h26.1v9.2h-16.5v7h15v9.1h-15v7.4l16.8.1z'
    />
    <path fill='#ff50c5' d='M319.9 28.8h13.2V42h-13.2z' />
    <path fill='#00aeef' d='M323.7 25h13.2v13.2h-13.2z' />
    <path fill='#1d4ea2' d='M323.7 28.8h9.4v9.4h-9.4z' />
  </svg>
);

export default Logotype;
