import React from 'react';
import MapImage from './SVG/MapImage';
import Chevron from './SVG/Chevron';

const MapSection = () => {
  return (
    <div className='section map-section'>
      <div className='container'>
        <div className='map-area'>
          <MapImage />
        </div>
        <div className='map-content-area'>
          <div className='map-intro'>
            <h4 className='map-main-header'>Europa</h4>
            <p className='map-paragraph'>
              Świadczymy usługi pozyskiwania licencji, doradztwa transakcyjnego
              i finansowego oraz usługi zarządzania finansami przedsiębiorstw.
            </p>
            <p className='map-paragraph'>
              Wspieramy naszych Klientów w wypracowaniu globalnych synergii i
              optymalizacji kapitałowej, procesowej oraz biznesowej.
            </p>
            <p className='map-paragraph'>
              Z uwagi na zmianę reguł gry po ostatnim kryzysie finansowym wiemy,
              które organy nadzory zacieśniły politykę wobec podmiotów
              działających na regulowanym rynku, a które stanowią atrakcyjny
              punkt
            </p>
          </div>
          <ul className='map-accordion-wrapper'>
            <li className='map-accordion-item' data-country='latvia'>
              <p className='map-accordion-title'>
                Litwa <Chevron className='arrow' />
              </p>
              <div className='map-accordion-content'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem
                augue, pulvinar vel nisi condimentum, volutpat ultrices nisl.
                Duis ultrices nisl id lacus consectetur viverra. Ut laoreet
                libero sem.
              </div>
            </li>
            <li className='map-accordion-item' data-country='switzerland'>
              <p className='map-accordion-title'>
                Szwajcaria <Chevron className='arrow' />
              </p>
              <div className='map-accordion-content'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem
                augue, pulvinar vel nisi condimentum, volutpat ultrices nisl.
                Duis ultrices nisl id lacus consectetur viverra. Ut laoreet
                libero sem.
              </div>
            </li>
            <li className='map-accordion-item' data-country='malta'>
              <p className='map-accordion-title'>
                Malta <Chevron className='arrow' />
              </p>
              <div className='map-accordion-content'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem
                augue, pulvinar vel nisi condimentum, volutpat ultrices nisl.
                Duis ultrices nisl id lacus consectetur viverra. Ut laoreet
                libero sem.
              </div>
            </li>
            <li className='map-accordion-item' data-country='poland'>
              <p className='map-accordion-title'>
                Polska <Chevron className='arrow' />
              </p>
              <div className='map-accordion-content'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem
                augue, pulvinar vel nisi condimentum, volutpat ultrices nisl.
                Duis ultrices nisl id lacus consectetur viverra. Ut laoreet
                libero sem.
              </div>
            </li>
            <li className='map-accordion-item' data-country='estonia'>
              <p className='map-accordion-title'>
                Estonia <Chevron className='arrow' />
              </p>
              <div className='map-accordion-content'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem
                augue, pulvinar vel nisi condimentum, volutpat ultrices nisl.
                Duis ultrices nisl id lacus consectetur viverra. Ut laoreet
                libero sem.
              </div>
            </li>
            <li className='map-accordion-item' data-country='giblaltar'>
              <p className='map-accordion-title'>
                Giblaltar <Chevron className='arrow' />
              </p>
              <div className='map-accordion-content'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem
                augue, pulvinar vel nisi condimentum, volutpat ultrices nisl.
                Duis ultrices nisl id lacus consectetur viverra. Ut laoreet
                libero sem.
              </div>
            </li>
            <li className='map-accordion-item' data-country='uk'>
              <p className='map-accordion-title'>
                Wielka Brytania <Chevron className='arrow' />
              </p>
              <div className='map-accordion-content'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sem
                augue, pulvinar vel nisi condimentum, volutpat ultrices nisl.
                Duis ultrices nisl id lacus consectetur viverra. Ut laoreet
                libero sem.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MapSection;
