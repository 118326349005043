import React from 'react';
import classNames from 'classnames';

import LinkedIn from './SVG/LinkedIn';

const ParterSection = ({
  sm,
  md,
  lg,
  name,
  surname,
  alt,
  link,
  aligned,
  children,
}) => {
  const alignedClass = classNames({
    'is-dark': aligned === 'right',
    'is-aligned-left': aligned === 'left',
  });

  const clearfixClass = classNames({
    cf: aligned === 'left',
  });

  return (
    <div
      data-scroll='team'
      className={`section partner-section ${alignedClass}`}
    >
      <div className='container partner-container'>
        <div className={`partner-content ${clearfixClass}`}>
          <h3 data-aos={`fade-${aligned}`} className='partner-header'>
            {name} <br />
            {surname}
          </h3>
          <p
            data-aos={`fade-${aligned}`}
            data-aos-delay='100'
            className='partner-subheader'
          >
            Partner
          </p>
          <p
            data-aos={`fade-${aligned}`}
            data-aos-delay='150'
            className='partner-paragraph'
          >
            {children}
          </p>
          <a
            data-aos={`fade-${aligned}`}
            data-aos-delay='200'
            href={link}
            target='_blank'
            rel='noopener noreferrer'
            className='partner-linkedin-link'
          >
            <LinkedIn />
          </a>
        </div>
        <div className='partner-image-container'>
          <picture>
            <source srcSet={lg} media='(min-width: 1024px)' />
            <source srcSet={md} media='(min-width: 768px)' />
            <img srcSet={sm} alt={alt} className='partner-image' />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default ParterSection;
