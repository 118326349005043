import React from 'react';

const Footer = () => {
  return (
    <div className='footer-container'>
      <footer className='section no-overflow footer-section'>
        <div className='container footer-content-container'>
          <h3 className='footer-header'>Kontakt</h3>
          <div className='contact-wrapper'>
            <div className='contact-credentials'>
              <p className='contact-phone'>
                <a
                  href='tel:+48600125742'
                  className='footer-link telephone-link'
                >
                  Tel: +48 600 125 742
                </a>
                <a
                  href='tel:+48733373200'
                  className='footer-link telephone-link'
                >
                  +48 733 373 200
                </a>
              </p>
              <p className='contact-mail'>
                email:
                <a href='mailto:hello@plave.in' className='footer-link'>
                  hello@plave.in
                </a>
              </p>
            </div>
            <div className='form-wrapper' />
          </div>
          <div className='copyright'>
            <div className='copyright-links'>
              <a href='#' className='footer-link is-transparent'>
                Polityka prywatności
              </a>
              <a href='#' className='footer-link is-transparent'>
                Warunki i zasady
              </a>
            </div>
            <p className='copyright-paragraph'>
              Copyright © 2018 Plave.com All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
